// ============================================================
// ATTENTION & COMMON RULE!!
// 関数を実装のみ（処理の実行は下部で実行する）
// 関数名には振る舞いを表す英語プラスFuncを付ける
// ============================================================

// レスポンシブ判定 & デバイス判定関数（PC,SP共通処理）
// -------------------------------------------------------------

function mq(device) {
  if (window.matchMedia('(max-width:768px)').matches && device === 'sp') {
    return true;
  } else if (
    window.matchMedia('(min-width:769px)').matches &&
    device === 'pc'
  ) {
    return true;
  }
  return false;
}
// FUNCTIONS

function topPcFunc() {
  $('.header').addClass('show');
  $('.menu-trigger').addClass('show');
  $('#mainTitle').addClass('show');
  var swiper = new Swiper('#galleryTop', {
    effect: 'fade',
    speed: 3000,
    autoplay: {
      delay: 3000
    },
    loop: true
  });
  $('#firstImageWrap').addClass('show');

}

function topLpPcFunc() {
  $('.header').addClass('show');
  $('.menu-trigger').addClass('show');
  $('#mainTitle').addClass('show');

  var swiper = new Swiper('#galleryTop', {
    effect: 'fade',
    speed: 3000,
    autoplay: {
      delay: 3000
    },
    loop: true
  });

  $('#firstImageWrap').addClass('show');
  var element01 = document.getElementById('firstText01');
  
  var titleShuffle01 = new ShuffleText(element01);
  titleShuffle01.duration = 1300;
  setTimeout(function(){
    element01.classList.add('show');
    titleShuffle01.start();
  }, 1000);

}




function subPageFunc() {
  console.log('SUB PAGE');
  // $('body').css('overflow', 'auto');
  // $('body').css('height', 'auto');
  $('.header').addClass('show');
  $('.menu-trigger').addClass('show');
  $('.main-image').addClass('show');
  $('#firstImageWrap').addClass('show');
  $('#firstText01').addClass('show');



}

function headerChangeBg() {
  var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  if (scrollTop < 1) {
    document.getElementById('headerWrap').classList.remove('bg');
  } else {
    document.getElementById('headerWrap').classList.add('bg');
  }
}

// eslint-disable-next-line no-unused-vars
function GetQueryString() {
  if (document.location.search.length > 1) {
    // 最初の1文字 (?記号) を除いた文字列を取得する
    var query = document.location.search.substring(1);

    // クエリの区切り記号 (&) で文字列を配列に分割する
    var parameters = query.split('&');

    // eslint-disable-next-line no-new-object
    var result = new Object();
    for (var i = 0; i < parameters.length; i++) {
      // パラメータ名とパラメータ値に分割する
      var element = parameters[i].split('=');

      var paramName = decodeURIComponent(element[0]);
      var paramValue = decodeURIComponent(element[1]);

      // パラメータ名をキーとして連想配列に追加する
      result[paramName] = decodeURIComponent(paramValue);
    }
    return result;
  }
  return null;
}

// ============================================================
// ATTENTION & COMMON RULE!!
// まとめて関数実行（※必要に応じて条件分岐を入れる）
// ページ個別に処理をする場合は「ページ固有のID名.lengthで処理を分岐」
// ============================================================

// --- ページの全データを読み込み後 ----------------------------------
$(window).on('load', function() {
  $('.sub-page .head-line').addClass('show');
});

// --- リサイズが走った場合 ----------------------------------------
$(window).on('resize', function() {});

// --- DOM生成後 -----------------------------------------------
$(function() {
  console.log('script.js');

  var observer = lozad('.lozad', {
    loaded: function(el) {
      // Custom implementation on a loaded element
      el.classList.add('loaded');

    }
  });
  // lazy loads elements with default selector as '.lozad'
  observer.observe({});
  var footArrow = document.getElementById("footArrow");
  footArrow.addEventListener('click', function(){
    footArrow.classList.toggle('show');
  }, false);

  if ($('#sportsMentalistaPage').length) {
    topLpPcFunc();
  } else if ($('#topPage').length) {
    topPcFunc();
  } else {
    subPageFunc();
  }

  // SMOOTH SCROOL FOR CLASS NAME
  $('a.scroll').click(function() {
    $('html,body').animate(
      { scrollTop: $($(this).attr('href')).offset().top },
      'slow',
      'swing'
    );
    return false;
  });
  // MENU
  $('#menu-trigger').click(function() {
    if ($('body').hasClass('menu-show')) {
      $('body').removeClass('menu-show');
    } else {
      $('body').addClass('menu-show');
    }
  });
 
  // PC or SPサンプル
  if (mq('sp')) {
  } else {
    
  }
});

window.addEventListener('scroll', _.throttle(headerChangeBg, 500));